@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@layer components {
  .form-builder .button span {
    @apply text-[14px] font-semibold;
  }

  .form-builder .button {
    @apply bg-[var(--btn-bg)] text-[var(--btn-color)] border-[var(--btn-border)] border-solid py-[6px] px-[16px] rounded transition-all;
  }

  .form-builder .button-no-bg {
    @apply text-[var(--btn-bg)] border-[var(--btn-bg)] border-solid py-[6px] px-[16px] rounded transition-all;
  }

  .form-builder .button:hover {
    @apply shadow-btn border-[var(--btn-border-hover)] bg-[var(--btn-bg-hover)] text-[--btn-color-hover];
  }

  .new-form-container {
    @apply duration-300;
  }

  .new-form-container:not(.editing) {
    @apply border border-solid border-transparent;
  }

  .new-form-container:not(.editing):hover {
    @apply border-[var(--label-border-color)];
  }

  .editing {
    @apply py-5 pl-4 pr-2 border border-solid border-black border-l-4 rounded w-full flex;
    --border-label-width: 1px;
    --border-label-style: dashed;
  }

  .border-list {
    @apply border-b border-0 border-solid border-[--divider-color];
  }

  .flex-center {
    @apply flex justify-between items-center;
  }

  .skeleton-animation {
    @apply animate-pulse rounded-full bg-[#ecf0f4] mr-2 text-transparent;
  }

  .skeleton-animation-portal {
    @apply animate-pulse rounded-full w-[150px] h-[20px] bg-[--main-bg] mr-2 text-transparent;
  }

  .form-builder .button.secondary {
    @apply border-[#DFE1E6] bg-white text-[#606266];
  }

  .form-builder .button.primary {
    @apply border-light-blue-primary bg-light-blue-primary text-white;
  }
  .half {
    @apply w-full formLg:w-[calc(50%-16px)];
  }

  .sidebar-collapse .half {
    @apply w-full formLg:w-[calc(50%-16px)];
  }

  .error {
    box-shadow: 0 0 0 1px var(--error-message-color);
  }

  .form-builder-container {
    @apply flex gap-x-[--list-content-gap] min-h-[--content-height] mx-auto;
  }

  .form-builder-content {
    @apply bg-white w-[--content-cmps-width] p-6 pb-[60px] flex flex-col;
  }

  .form-list-container {
    @apply w-full min-h-[calc(var(--content-height)-210px)]  flex flex-wrap mb-[36px] gap-y-4 gap-x-8 justify-between content-start;
  }

  .form-title-popup {
    @apply absolute left-[16px] bg-slate-100 p-4 break-keep w-[150%] invisible opacity-0 group-hover:visible group-hover:opacity-[1] group-hover:duration-300 z-[1];
  }

  .form-list-title {
    @apply whitespace-nowrap overflow-hidden text-ellipsis max-formLg:max-w-[220px] max-formLg:block cursor-pointer;
  }

  .form-builder .button.secondary {
    @apply border-[#DFE1E6] bg-white text-[#606266];
  }

  .incident-content {
    @apply p-4 pb-[32px] bg-white mt-2;
  }
}

@layer utilities {
  .shadow-btn {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .break-word {
    word-break: break-word;
  }
}
